import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Heading, Map } from '../../components';

import css from './ListingPage.module.css';
import {hexToFilter} from "../../styles/hexToFilter";

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true, filter: ''};
  }

  componentDidMount() {
    const marketplaceColor = getComputedStyle(document.documentElement).getPropertyValue('--marketplaceColor');
    this.setState({ filter: hexToFilter(marketplaceColor) });
  }

  render() {
    const { className, rootClassName, geolocation, publicData, iconURL, listingId, mapsConfig, mapIconURL } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    this.mapIconElement ??= (() => {
      if (mapIconURL && typeof document !== 'undefined') {
        const element = document.createElement('img');
        element.src = mapIconURL;
        element.style.width = '48px';
        element.style.height = '48px';
        element.style.objectFit = 'cover';
        element.style.objectPosition = 'center';
        element.style.borderRadius = '50%';
        return element;
      }
    })();

    const mapProps = mapsConfig.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, mapsConfig.fuzzy.offset, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} mapIcon={this.mapIconElement} useStaticMap={this.state.isStatic} />;

    return (
      <div className={classes}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {iconURL && (
            <img src={iconURL} style={{ height: '1em', width: '1em', marginRight: '5px', filter: this.state.filter }} />
          )}
          <FormattedMessage id="ListingPage.locationTitle" />
        </Heading>
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              // this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
  iconURL: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
  iconURL: string,
};

export default SectionMapMaybe;

import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import {hexToFilter} from "../../styles/hexToFilter";
import {useCSSVariable} from "../../styles/useCSSVariable";

const SectionMultiEnumMaybe = props => {
  const marketplaceColor = useCSSVariable('--marketplaceColor', '#6c3a87');
  const { heading, options, selectedOptions, iconURL } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }
  const filter = hexToFilter(marketplaceColor);

  return (
    <div className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {iconURL && (
          <img src={iconURL} style={{ height: '1em', width: '1em', marginRight: '5px', filter }} />
        )}
        {heading}
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;

import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import {hexToFilter} from "../../styles/hexToFilter";
import {useCSSVariable} from "../../styles/useCSSVariable";

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const marketplaceColor = useCSSVariable('--marketplaceColor', '#6c3a87');
  const { text, markdownComponents, heading, iconURL, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  const filter = hexToFilter(marketplaceColor);

  return (text || markdownComponents) ? (
    <div className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {iconURL && (
          <img src={iconURL} style={{ height: '1em', width: '1em', marginRight: '5px', filter }} />
        )}
        {heading}
      </Heading>

      {markdownComponents ??
      <p className={textClass}>{content}</p>
      }
    </div>
  ) : null;
};

export default SectionTextMaybe;
